










































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import {
  useDisabledBackDate,
  useInventory,
  useProduct,
  useWorkOrder,
} from "@/hooks";
import { SparePartRequestMapper } from "@/mapper/SparepartRequest.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { EnumSparePartRequestStatus } from "@/models/enums/SparePartRequest.enum";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import { ProductStockResponseDto } from "@/models/interface/master-product";
import {
  CreateWorkOrderFormState,
  SparePartDetailHeaderResDto,
  SparePartDetailUnitResDto,
} from "@/models/interface/sparepart-request";
import { WorkOrderCreateRequestDto } from "@/models/interface/work-order";
import { sparePartRequestService } from "@/services/sparepart-request.service";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { CreateWorkOrderSparePartFormState } from "../../../models/interface/sparepart-request/CreateWorkOrderFormState.interface";

@Component({})
export default class CreateWorkOrder extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledBackDate = useDisabledBackDate;

  @Ref("formModel")
  formModel!: FormModel;

  formRules = {
    branch: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    workOrderDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    mechanic: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    scheduleOrderDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  sparePartRequestDetail: SparePartDetailHeaderResDto = {
    address: "",
    addressRef: "",
    branchId: "",
    branchName: "",
    customerId: "",
    customerName: "",
    customerRef: "",
    description: "",
    documentNo: "",
    mechanicId: "",
    mechanicName: "",
    requestDate: "",
    sparePartRequestId: "",
    status: "" as EnumSparePartRequestStatus,
    units: [],
  };

  formData: CreateWorkOrderFormState = {
    branch: undefined,
    workOrderDate: null,
    scheduleOrderDate: null,
    mechanic: undefined,
    description: undefined,
    customer: undefined,
    customerPICName: undefined,
    documentNumber: undefined,
    customerAddress: undefined,
    units: [],
    spareParts: [],
  };

  loading = {
    find: false,
    submit: false,
    fetchPartNumber: false,
    fetchPartName: false,
  };

  unitColumns = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_category"),
      dataIndex: "category",
      key: "category",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      key: "capacity",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_spec"),
      dataIndex: "specification",
      key: "specification",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      key: "model",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      customRender: (text: string) => text || "-",
    },
  ];

  partColumns = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_part_reference"),
      dataIndex: "partReference",
      key: "partReference",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "partNumber",
      key: "partNumber",
      scopedSlots: { customRender: "partNumber" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "partName",
      key: "partName",
      scopedSlots: { customRender: "partName" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom.label",
      key: "uom",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("common.reference-text", { text: this.$t("lbl_uom") }),
      dataIndex: "uomRef",
      key: "uomRef",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_part_location"),
      dataIndex: "partLocation.label",
      key: "partLocation",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_qty_wo"),
      dataIndex: "qty",
      key: "qty",
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      key: "qtyAvailable",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      key: "notes",
      scopedSlots: { customRender: "notes" },
    },
  ];

  partNumberOptions: Option<ProductStockResponseDto>[] = [];
  partNameOptions: Option<ProductStockResponseDto>[] = [];
  customerAddressOptions: Option<string>[] = [];
  selectedPartRowKeys: Array<string> = [];
  selectedUnitRowKeys: Array<string> = [];

  get branchName(): string {
    return this.formData?.branch?.label || "-";
  }

  get mechanicName(): string {
    return this.formData?.mechanic?.label || "-";
  }

  get customerName(): string {
    return this.formData?.customer?.label || "-";
  }

  setCustomerAddressOptions(units: SparePartDetailUnitResDto[]): void {
    const options: Set<string> = new Set();
    const filtered = units.filter(
      item => !item.isUsed && !!item.location?.trim()
    );
    for (const item of filtered) {
      options.add(item.location);
    }
    this.customerAddressOptions = [...options].map(item => ({
      key: item,
      label: item,
      value: item,
      meta: item,
    }));
  }

  mounted() {
    this.getDetail(this.$route.params.id);
    this.loadPartOptions();
  }

  loadPartOptions(): void {
    this.setPartLoading("code", true);
    this.setPartLoading("name", true);
    this.getParts(new RequestQueryParams())
      .then(data => {
        this.partNumberOptions = this.toPartOptions("code", data);
        this.partNameOptions = this.toPartOptions("name", data);
      })
      .finally(() => {
        this.setPartLoading("code", false);
        this.setPartLoading("name", false);
      });
  }

  getDetail(id: string) {
    this.loading.find = true;
    sparePartRequestService
      .getDetail(id)
      .then(response => {
        this.sparePartRequestDetail = response;
        this.formData =
          SparePartRequestMapper.mapDetailToCreateWorkOrderFormState(
            this.sparePartRequestDetail
          );
        this.setCustomerAddressOptions(this.sparePartRequestDetail.units);
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  handleBack(): void {
    this.$router.go(-1);
  }

  handleGenerate(): void {
    this.formData.units = this.sparePartRequestDetail.units
      .filter(
        unit =>
          !unit.isUsed && unit.location === this.formData.customerAddress?.key
      )
      .map(unit => ({
        id: generateUUID(),
        assetId: unit.assetId ?? "",
        unitCode: unit.unitCode,
        serialNumber: unit.serialNumber,
        category: unit.category,
        capacity: unit.capacity,
        specification: unit.spec,
        type: unit.type,
        model: unit.model,
        location: unit.location,
      }));
    const selectedUnitCodes = this.formData.units.map(unit => unit.unitCode);

    this.formData.spareParts = this.sparePartRequestDetail.units
      .filter(unit => selectedUnitCodes.includes(unit.unitCode))
      .flatMap(unit =>
        unit.items.map(item => ({
          id: generateUUID(),
          unitCode: unit.unitCode,
          sparePartRequestItemId: item.sparePartItemId,
          partReference: item.partName,
          partNumber: undefined,
          partName: undefined,
          uom: undefined,
          uomRef: item.uomReference,
          partLocation: undefined,
          qty: item.qty,
          qtyAvailable: 0,
          notes: "",
          partNumberOptions: [],
          partNameOptions: [],
          useLocalPartNumberOptions: false,
          useLocalPartNameOptions: false,
          loadingPartNumbers: false,
          loadingPartNames: false,
        }))
      );
  }

  handleDeleteUnitRows(): void {
    this.formData.units = this.formData.units.filter(
      unit => !this.selectedUnitRowKeys.includes(unit.id)
    );

    const unitCodes: string[] = [];
    this.formData.units.forEach(unit => {
      if (unit.unitCode) {
        unitCodes.push(unit.unitCode);
      }
    });

    this.formData.spareParts = this.formData.spareParts.filter(
      sparePart =>
        !!sparePart.unitCode && unitCodes.includes(sparePart.unitCode)
    );
  }

  handleDeleteSparePartRows(): void {
    this.formData.spareParts = this.formData.spareParts.filter(
      sparePart => !this.selectedPartRowKeys.includes(sparePart.id)
    );
  }

  onSelectedUnitRowChange(keys: Array<string>): void {
    this.selectedUnitRowKeys = keys;
  }

  onSelectedPartRowChange(keys: Array<string>): void {
    this.selectedPartRowKeys = keys;
  }

  async handleSubmit(): Promise<void> {
    try {
      await this.formModel.validate();

      if (this.isUnitsInvalid()) {
        this.showNotifWarning("notif_work_order_units_invalid");
        return;
      }

      if (this.isSparepartInvalid()) {
        this.showNotifWarning("notif_work_order_spareparts_invalid");
        return;
      }

      this.createWorkOrder(this.formData);
    } catch (error) {
      this.showNotifWarning("notif_validation_error");
    }
  }

  isUnitsInvalid(): boolean {
    const isEmpty = !this.formData.units.length;
    return isEmpty;
  }

  isSparepartInvalid(): boolean {
    const isEmpty = !this.formData.spareParts.length;
    const invalidSparepart = !!this.formData.spareParts.find(
      item =>
        !item.unitCode ||
        !item.partName ||
        !item.partNumber ||
        !item.uom ||
        !item.partLocation ||
        !item.qty
    );
    return isEmpty || invalidSparepart;
  }

  createWorkOrder(data: CreateWorkOrderFormState): void {
    const { create } = useWorkOrder();
    const req: WorkOrderCreateRequestDto =
      SparePartRequestMapper.mapCreateWorkOrderFormStateToRequestDto(
        data,
        this.sparePartRequestDetail.sparePartRequestId
      );

    this.loading.submit = true;
    create(req)
      .then(({ documentNumber }) => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  //region select part
  setPartLoading(label: "code" | "name", loading: boolean): void {
    if (label === "code") {
      this.loading.fetchPartNumber = loading;
    } else {
      this.loading.fetchPartName = loading;
    }
  }

  handlePartSearch(
    label: "code" | "name",
    record: CreateWorkOrderSparePartFormState,
    val?: string
  ): void {
    debounce(() => {
      const { searchProductInStock } = useProduct();

      const params = new RequestQueryParams();
      params.search = searchProductInStock({
        [label]: val,
        desc: val,
        type: val,
        categoryName: val,
      });

      if (label === "code") {
        record.loadingPartNumbers = true;
      } else {
        record.loadingPartNames = true;
      }
      this.getParts(params)
        .then(data => {
          const options = this.toPartOptions(label, data);
          if (label === "code") {
            record.partNumberOptions = options;
            record.useLocalPartNumberOptions = true;
          } else {
            record.useLocalPartNameOptions = true;
            record.partNameOptions = options;
          }
        })
        .finally(() => {
          if (label === "code") {
            record.loadingPartNumbers = false;
          } else {
            record.loadingPartNames = false;
          }
        });
    });
  }

  handlePartChange(
    record: CreateWorkOrderSparePartFormState,
    option?: Option<ProductStockResponseDto>
  ): void {
    record.partName = undefined;
    record.partNumber = undefined;
    record.partLocation = undefined;
    record.qtyAvailable = 0;

    const part = this.findPartOption(record, option?.key as string)?.meta;
    if (part) {
      record.partName = {
        label: `${part.name} (${part.description})`,
        key: part.id,
      };
      record.partNumber = {
        label: part.code || "",
        key: part.id,
      };
      record.uom = {
        key: part.uomId,
        label: part.uomName,
      };
      if (part.defaultLocationId && part.defaultLocation) {
        record.partLocation = {
          key: part.defaultLocationId,
          label: part.defaultLocation,
        };
      }
    }

    if (record.partLocation) {
      this.findInventory(
        {
          rackId: part?.defaultLocationId ?? "",
          uomId: part?.uomId ?? "",
          productId: part?.id ?? "",
        },
        ({ available }) => {
          record.qtyAvailable = available || 0;
        }
      );
    }
  }

  findLabel(
    { code, name, description }: ProductStockResponseDto,
    field: "code" | "name"
  ): string {
    if (field === "name") {
      return `${name} (${description})`;
    }
    return code;
  }

  findPartOption(
    record: CreateWorkOrderSparePartFormState,
    key?: string
  ): Option<ProductStockResponseDto> | undefined {
    if (!key) {
      return undefined;
    }

    return (
      this.partNumberOptions.find(e => key === e.value) ??
      this.partNameOptions.find(e => key === e.value) ??
      record.partNumberOptions.find(e => key === e.value) ??
      record.partNameOptions.find(e => key === e.value)
    );
  }

  toPartOptions(
    label: "code" | "name",
    dtos: ProductStockResponseDto[]
  ): Option<ProductStockResponseDto>[] {
    return dtos.map(item => ({
      label: this.findLabel(item, label),
      value: item.id,
      key: item.id,
      meta: item,
    }));
  }

  async getParts(
    params: RequestQueryParams
  ): Promise<ProductStockResponseDto[]> {
    const { findAllProductInStock } = useProduct();
    const response = await findAllProductInStock(params);

    return response.data;
  }

  async findInventory(
    {
      rackId,
      uomId,
      productId,
    }: { rackId: string; uomId: string; productId: string },
    cb: (data: InventoryLineResponseDto) => void
  ): Promise<void> {
    const { findAllInventoryLine } = useInventory();
    const findByRack = new SearchBuilder()
      .push(["warehouseLocation.secureId", rackId])
      .and()
      .push(["uom.secureId", uomId])
      .and()
      .push(["product.secureId", productId])
      .and()
      .push(["available", "0"], { ht: true })
      .build();
    const { totalElements, data } = await findAllInventoryLine({
      search: findByRack,
    });
    if (totalElements > 0) {
      cb(data[0]);
    }
  }
  //region select part
}
